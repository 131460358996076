<template>

    <div :class="{'w-full h-full px-3 py-4': mobile, 'h-full p-2 overflow-hidden': !mobile}">

        <div v-if="mobile" class="absolute top-0 left-0 w-full h-20 z-30" 
        :class="{'mt-28': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarComercial')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>

        <div class="h-full w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

            <All :model="model" :immediate="true" :config="config" :query="query" v-slot="{data,loading}">

                <div v-if="!loading" class="mt-2" :class="{'h-full w-full grid grid-cols-3 gap-3 overflow-hidden': !mobile, 'h-auto': mobile}">

                    <div v-if="mobile" class="h-auto flex flex-row justify-start items-center px-2">
                        <span class="text-md font-semibold w-auto mr-2" :class="'text-dfont-'+mode">{{$t('visits')}}</span>
                    </div>

                    <Intersect @enter="onEnter" @leave="onLeave" >

                        <div class="px-2 mt-2 mb-8" :class="{'col-span-2 overflow-hidden h-full w-full': !mobile, 'h-72': mobile}">
                            <div class="h-full rounded-lg flex flex-row justify-center items-center overflow-hidden relative">
                                <maptool :data="filteredComercials(data.visits)"></maptool>
                                <span v-if="data.visits_latitude_longitude.length == 0" class="text-lg text-center bg-modal_transparent font-bold text-white absolute px-2 z-20">{{ $t('nolocationtext') }}</span>
                            </div>
                        </div>

                    </Intersect>

                    <div class="" :class="{'overflow-auto': !mobile}">

                        <client v-for="(el,index) in filteredComercials(data.visits)" :key="index" :data="el" :reducetype="true" :class="'bg-box-'+mode"></client>

                        <div v-if="filteredComercials(data.visits).length == 0" class="h-20 mt-6 w-full flex flex-row justify-center items-center">
                            <span class="text-darkgreen">{{$t('noCustomers')}}</span>
                        </div>
                    
                    </div>

                </div>

                <div v-else class="h-32 relative rounded-lg shadow_box" :class="'bg-module-'+mode">
                    <loader :loading="loading" />
                </div>

            </All>

            <div class="h-6"></div>

        </div>

    </div>
</template>

<script>
    import { state, actions } from '@/store';
    import maptool from '../../components/MapTool.vue';
    import { All } from '@/api/components';
    import client from '@/components/client.vue';
    import Intersect from 'vue-intersect';
    import loader from '../../components/loader.vue';

    export default {
        name: 'visitas',
        components:{
            maptool,
            All,
            client,
            Intersect,
            loader
        },
        data(){
            return{
                isVisible: false,
                searchInput: ''
            }
        },
        methods:{
            onLeave(){
                this.isVisible = true
            },
            onEnter(){
                this.isVisible = false
            },
            goClient(client){
                actions.setClientSelected(client)
                this.$router.push('client')
            },
            tofilters(){
                this.$router.push({name: 'filterperiodvisits'})
            },
            filteredComercials(data){
                return Object.values(data).filter(post => {
                    return post['Name'].toLowerCase().includes(this.searchInput.toLowerCase())
                })
            },
            colortype(type){

                switch (type) {

                    case 'ALMACENISTAS':
                        return 'blue'
                        break;
                    case 'PARTICULARES':
                        return 'purple'
                        break;
                    case 'CONSTRUCTORAS':
                        return 'brown'
                        break;
                    case 'VARIOS':
                        return 'green'
                        break;
                }

            }
        },
        computed: {
            monthName(){
            return this.$moment({M: state.month -1 }).format('MMMM')
            },
            user() {
                return state.user;
            },
            comercial(){
                return state.comercialSelected
            },
            periodo(){
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            config(){

                if(state.user.rol.Name != 'Comercial'){

                    return{
                        data:{
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            period: state.period,
                            Zone: state.idZone
                        }
                    }

                } else {

                    return{
                        data:{
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            period: state.period,
                            IdUser: state.user.id,
                            Zone: state.idZone
                        }
                    }

                }
            },
            model(){
                return 'ComercialVisitsZone'
            },
            query(){
                return {
                    period: state.period,
                    IndustrialGroup: state.industrialGroup,
                    month: state.month
                }
            },
            zone(){
                return state.zone
            },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        },
        mounted(){

        }
    }
</script>
